only
.searchbar {
    width: 100%;
    padding: 0%;
    padding-top: 0;
}

.searchbar-input {
    width: 100%;
    padding: 1.5%;
    border-radius: 5px;
    border: solid 1px white;
    background-color: rgba(255, 255, 255, 1);
    color: rgb(46, 46, 46);
    font-size: 1em;
}

.searchbar-input:focus {
    border: solid 1px aquamarine;
}

.searchContainer {
    width: 100%;
    padding: 0%;
    margin: auto;
    text-align: center;
}

.itemContainer {
    width: 100%;
}

.gridContainer-item-spajz {
    width: 100%;
    grid-template-columns: 35% 15% 25% 25%;
    padding-left: 1em;
    padding-right: 1em;
    align-items: center;
    height: 3em;
    text-align: left;
    justify-content: space-evenly;
    display: grid;
}

.gridContainer-item-buylist {
    display: grid;
    width: 100%;
    grid-template-columns: 90% 5% 5%;
    height: 3em;
    padding-left: 1em;
    padding-right: 1em;
    text-align: left;
    justify-content: space-evenly;
    contain: content;
    align-items: center;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}

.gridContainer-head-spajz {
    display: grid;
    width: 100%;
    grid-template-columns: 25% 25% 25% 25%;
    padding: 10px;
    align-items: center;
    padding-right: 0;
    padding-left: 0;
    font-weight: bold;
    justify-content: space-between;
    contain: content;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}


.gridContainer-head-buylist-item:hover {
    background: rgba(0, 0, 0, 0.2) !important;
    border: none !important;
    color: black !important;
}

.btn-check:checked + .gridContainer-head-buylist-item {
    background: rgb(196, 167, 224) !important;
    border: none !important;
    color: black !important;
}

.gridContainer-separator {
    border: 1px rgba(128, 128, 128, 0.316) solid;
}

.gridContainer-item-buylist-count {
    font-weight: bold;
    text-align: right;
}

.amountButtonsContainer {
    display: grid;
    grid-template-columns: 0.5fr 2fr 0.5fr;
    width: 100%;
    padding-left: 1.5em;
    margin: 0;
    justify-content: center;
}

.amountButton {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    overflow: hidden;
}

.amountCount {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
}

.cross-text {
    color: rgba(0, 0, 0, 0.5) !important;
}

.gridContainer-head-item-icon {
    -webkit-text-stroke-width: 0.8px;
    font-size: 1.5em;

}

.cross {
    height: 2px;
    background-color: rgba(0, 0, 0, 0.5);
    box-sizing: border-box;
    width: 85%;
    position: fixed;
}

.gridContainer-head-buylist {
    width: 100%;
    padding: 10px;
    align-items: center;
    padding-right: 0;
    padding-left: 0;
    justify-content: space-evenly;
    align-content: center;
    display: flex;
    grid-template-columns: 95% 5%;
}

.gridContainer-head-buylist-item {
    font-weight: bold;
    width: 180px;
    height: 60px;
    align-items: center;
    display: grid;
    background: rgba(0, 0, 0, 0.1);
    border: none;
    color: black;
    padding: 5px;
    margin: 7px;
    text-align: center;
    grid-template-columns: 80% 20%;
}

.dropdown-toggle {
    font-weight: bold !important;
    width: 180px !important;
    height: 50px !important;
    align-items: center !important;
    display: flex !important;
    justify-content: space-evenly;
    background: rgba(0, 0, 0, 0.1) !important;
    border: none !important;
    color: black !important;
    padding: 5px !important;
}
    
.apitest-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    margin: 0 0 10px 0;
    gap: 10px;
}

.apitest-column {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    margin: 0 0 10px 0;
    gap: 10px;
    width: 300px;
}
:root {
    --main-bg-color: #f2f2f2;
    --main-fg-color: #e1e1e1;
    /* --main-navbar-color: #f21212; */
    --main-navbar-color: #121212;
}

@font-face {
    font-family: 'Quicksand';
    src: local('Quicksand'), url(../Fonts/Quicksand/Quicksand-VariableFont_wght.ttf) format('truetype');
    font-display: swap;
}

* {
    font-family: Quicksand;
}

html,
body {
    padding: 0;
    margin: 0;
    height: 100vh;
    background-color: var(--main-bg-color);
    overflow: hidden;
}

#root {
    height: 100vh;
    display: flex;
    flex-direction: column;
}

.navbar {
    z-index: 1;
    background-color: var(--main-navbar-color) !important;
    box-shadow: 0px 0px 10px 10px rgba(0, 0, 0, 0.2);
    text-align: center;
    width: 100%;
    height: 60px;
    color: white;
    font-size: 13px;
    font-weight: bold;
}

.footer {
    height: 40px !important;
}

.footer-text {
    font-size: 13px;
    font-weight: bold;
}

.navbar-link {
    color: white !important;
}

.navbar-link-active {
    color: #C5BAEE !important;
}

.content {
    overflow: auto;
    height: 100vh;
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: var(--main-fg-color);
    z-index: 0;
    top: 0;
    left: 0;
    right: 0;
}

.spinner {
    position: absolute;
    top: 20%;
    left: 50%;
    margin-left: -25px;
    margin-top: -50px;
    height: 50px;
    width: 50px;
}